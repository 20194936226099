function showModal(modalElement: HTMLElement): void {
	modalElement.classList.add('active');

	const video = modalElement.getElementsByTagName('video')[0];

	function closeModal(e: Event): void {
		const targetElement = e.target as HTMLElement;
		const isInsideModal: boolean = !!targetElement.closest('.modal-body');
		const isModalOpenButton: boolean = targetElement.hasAttribute('data-target');

		if (!isModalOpenButton && !isInsideModal) {
			video.pause();
			video.currentTime = 0;
			modalElement.classList.remove('active');
			document.body.style.paddingInline = '';
			document.body.classList.remove('overflow-hidden');
			window.removeEventListener('click', closeModal);
		}
	}

	window.addEventListener('keydown', (e) => {
		if (e.key === 'Escape') {
			modalElement.classList.remove('active');
			document.body.style.paddingInline = '';
			document.body.classList.remove('overflow-hidden');
			window.removeEventListener('click', closeModal);
		}
	});

	window.addEventListener('click', closeModal);
}

export function modal(): void {
	const modalButtons: NodeListOf<HTMLElement> = document.querySelectorAll('[data-modal]');
	// biome-ignore lint/complexity/noForEach: <explanation>
	modalButtons.forEach((button) => {
		button.addEventListener('click', function (e: Event) {
			e.preventDefault(); // Prevents the default behavior of anchor/button click
			const target: string | null = this.getAttribute('data-target');
			const modalElement: HTMLElement | null = document.querySelector(`#${target}`);

			const modalClose = modalElement?.querySelector('[modal-close]');

			modalClose?.addEventListener('click', () => {
				if (!modalElement) return;
				modalElement.classList.remove('active');
				document.body.style.paddingInline = '';
				document.body.classList.remove('overflow-hidden');
			});

			if (modalElement) {
				const scrollWidth: number = window.innerWidth - document.documentElement.clientWidth;
				document.body.style.paddingInline = `${scrollWidth}px`;
				document.body.classList.add('overflow-hidden');
				showModal(modalElement);
			}
		});
	});
}
